import React from 'react'
import Swal from 'sweetalert2'

import Wizard from '@modules/form-wizard'
import { fullName } from '@modules/form-wizard/utils'
import { listKeys } from '@helpers/objects'
import SERVICE_PROPS from '@helpers/extract-service-props'
import occupations from '@constants/occupations'
import countries from '@constants/countries'
import islands from '@constants/islands'

import passportPhotoExample from '@helpers/passport-photo-example'

export default class CharacterReference extends React.Component {
  state = {
    loading: false,
  }

  title = 'Character Certificate'
  resource = '/police_certificates'
  form_key = 'police_certificate'

  defaults = {
    digital_cert_only: false,
    physical_copy: true,
  }

  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        digital_cert_only: false,
        physical_copy: true
      },
    },
  }

  get isBahamian () {
    return $app.applicant?.country_of_citizenship == 'bahamas'
  }

  defaults = () => {
    const res = {
      full_name: fullName($app.applicant),
      amount: 1,
    }

    if (this.isBahamian) {
      res.birth_place = 'Bahamas'
      res.passport_issue_country = 'Bahamas'
    }

    return res
  }

  showPassort = (form) => {
    if (this.isBahamian) {
      if (form.travel_job != 'Travel') {
        return true
      }
    } else {
      return false
    }
  }

  canned = [
    `
    Thank you for your request. We require that you come in person for a finger print scanning.
    Please visit the Royal Bahamas Police Force Head Office at New Providence, Thompson Blvd. to complete your application process
    `,
  ]

  fee = () => ($app.applicant.verified_citizenship ? 5 : 10)

  custom_action = {
    text: 'Request Prints',
    icon: 'fingerprint',
    fn: async record => {
      const person = listKeys(
        record.user,
        'first_name',
        'middle_name',
        'last_name'
      )
        .filter(n => n)
        .join(' ')
        .capitalize()

      const { isConfirmed } = await Swal.fire({
        icon: 'question',
        title: 'Request Fingerprints',
        text: `Are you sure want to request that ${person} come in for fingerprinting?`,
        showCancelButton: true,
      })

      if (isConfirmed) {
        const { resource, form_key } = this
        this.setState({ loading: true })

        try {
          await $app.axios.put(resource + '/update_application', {
            [form_key]: {
              form_num: record.form_num,
              application_decision: 'processing',
              finger_print_rq: true,
            },
          })

          await $app.axios.post('/emails', {
            email: record.user.email,
            subject: 'MyGateqway Portal Update - Character Reference Request',
            message: `
              Good day ${record.user.first_name.capitalize()},
              <br/>
              In order to complete your Character Reference request, we ask that you
              come in person to the Central Detective Unit located on Thompson Blvd.,
              New Providence for fingerprinting.
              <br/><br />
              Thank you for using MyGateway Portal.
            `,
          })
        } catch (err) {
          console.error(err)
        } finally {
          this.setState({ loading: false })
        }
      }
    },
  }

  validate = form => {
    if (form.full_name.trim().split(' ').length < 3 && !form.no_middle_name) {
      return 'Please be sure to enter your middle name'
    }
  }

  fields = form => [
    {
      name: 'travel_job',
      label: 'Purpose',
      options: ['Travel', 'Employment', 'Immigration'],
      hint: 'Will this document be used for travel, employment or immigration?',
    },
    {
      name: 'digital_cert_only',
      label: 'Digital Certificate',
      type: 'select:bool',
      hide: true,
      save: true,
      view: true,
    },
    {
      name: 'physical_copy',
      label: 'Physical Copy',
      type: 'select:bool',
      hide: true,
      save: true,
      view: true,
    },
    {
      name: 'passport_verified',
      admin: true,
    },
    'street_address',
    {
      name: 'birth_place',
      label: 'Country Of Birth',
      options: countries,
    },
    {
      name: 'birth_island',
      label: 'Island Of Birth',
      options: islands,
      hide: form.birth_place !== 'Bahamas',
      use_profile: 'island',
    },
    {
      name: 'occupation',
      options: occupations,
    },
    {
      heading: 'Passport Details',
      hide: form.travel_job != 'Travel',
    },
    {
      name: 'passport_number',
      hide: form.travel_job != 'Travel',
    },
    {
      name: 'full_name',
      hint: "Enter the full name on your passport if it's not the same as your NIB",
      save: true,
      view: !!form.full_name,
      required: !form.no_middle_name,
      disabled: form.no_middle_name,
    },
    {
      name: 'no_middle_name',
      view: false,
      type: 'checkbox',
      label: 'I have no middle name',
      required: false,
    },
    {
      name: 'passport_issue_date',
      label: 'Issue Date',
      type: 'date',
      showMonthDropdown: true,
      showYearDropdown: true,
      hide: form.travel_job != 'Travel',
    },
    {
      name: 'passport_expiry_date',
      label: 'Expiration Date',
      type: 'date',
      showMonthDropdown: true,
      showYearDropdown: true,
      hide: form.travel_job != 'Travel',
    },
    {
      name: 'identification',
      type: 'file:image',
      label: 'Identification',
      key: f => f.image[0],
      hide: this.showPassort(form),
      tooltip: 'Only the information page is required',
      hint: passportPhotoExample,
    },
    {
      name: 'passport_issue_country',
      label: 'Issuing Country',
      hide: form.travel_job != 'Travel',
      disabled: this.isBahamian,
      options: countries,
    },
    {
      name: 'work_permit_upload',
      label: 'Work Permit',
      type: 'file:image',
      key: f => f.image[1],
      hide: this.isBahamian,
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
