import React, { useEffect, useState } from 'react'
import { ValidatorForm } from 'react-form-validator-core'

import Fields from '@shared/form-fields'

const ItemCriminalHistory = ({ record, fields, submit, closeModal, tableName }) => {
  const [criminalFields, setCriminalFields] = useState([]);
  const [totalConvictions, setTotalConvictions] = useState(0);
  const [newConviction, setNewConviction] = useState({ id: 0, date: '', court: '', conviction: '', sentence: '' });

  // Selectable Array for All available Courts
  const courts = [
    { label: 'Supreme', value: 'supreme' },
    { label: 'Magistrate', value: 'magistrate' },
  ]

  // Grab Criminal History data from record data
  useEffect(() => {
    // const generateFields = () => (
    //   fields
    //   .filter(f => !!f.columns && record[f.name]?.length)
    //   .reduce(
    //     (obj, f) => ({
    //       ...obj,
    //       [f.name]: record[f.name].map(r => ({
    //         ...r,
    //         date: r.date || '',
    //         court: r.court || '',
    //         conviction: r.conviction || '',
    //         sentence: r.sentence || '',
    //       })),
    //     }),
    //     {}
    //   )
    // )

    const generateFields = () => (
      fields
      .filter(f => !!f.columns && record[f.name]?.length)
    )

    setCriminalFields(generateFields());
  }, [fields, record])

  useEffect(() => {
    const computeTotal = arr => {
      setTotalConvictions(arr.length)
    }
  
    computeTotal(criminalFields)
  }, [criminalFields, setTotalConvictions])

  const on = {
    setInput: (val, i, key) => {
      const newHistory = [...criminalFields];
      newHistory[i][key] = val;

      setCriminalFields(newHistory);
    }
  }

  const formSubmit = async ev => {
    ev && ev.preventDefault()

    // for (const [key, value] of Object.entries(state)) {
    //   if (!Array.isArray(value)) continue

    //   state[key] = value.reduce((arr, val) => {
    //     if (val.status === 'denied') {
    //       arr.push({ ...val, status: 'denied' })
    //       return arr
    //     }

    //     arr.push({ ...val, status: 'approved' })
    //     return arr
    //   }, [])
    // }

    // Remove ID field from criminalFields state array
    const submitHistory = criminalFields.map(x => ({ date: x.date, court: x.court, conviction: x.conviction, sentence: x.sentence }));
    console.log('CriminalFields\n', submitHistory);
    submit(submitHistory)
  }

  const addConvict = (ev) => {
    ev && ev.preventDefault()

    const newHistory = [...criminalFields];
    // Set id of new Conviction which is equal to key index
    const createConviction = { ...newConviction, 'id': criminalFields.length + 1 }
    newHistory.push(createConviction)

    // Add new conviction to original list of convictions
    setCriminalFields(newHistory);

    // Reset new Conviction form
    setNewConviction({ id: 0, date: '', court: '', conviction: '', sentence: '' });
  }

  const removeConvict = (conviction) => {
    console.log(conviction)
    const newHistory = criminalFields.filter(x => x.id != conviction.id);
    // Make sure the id field in the array changes to match the new number of records
    const newHistoryNewIdOrder = newHistory.map((x, i) => ({ ...x, id: i + 1 }))

    // Add new conviction to original list of convictions
    setCriminalFields(newHistoryNewIdOrder);
  }


  const headings = (
    <thead>
      <tr>
        <th>Date</th>
        <th>Court</th>
        <th>Conviction</th>
        <th>Sentence</th>
        <th>Action</th>
      </tr>
    </thead>
  )

  const colgroup = (
    <colgroup>
      <col span='1' width='160px'></col>
      <col span='1' width='140px'></col>
      <col span='1' width='160px'></col>
      <col span='1' width='160px'></col>
      <col span='1' width='128px'></col>
    </colgroup>
  )

  const addItemRow = (
    <div className='form-wizard-form-table table'>
      <table className='table table-striped table-striped-bg-black'>
        {colgroup}
        {headings}
        <tbody>
          <tr className={'table-default'}>
            <td>
              <Fields.Date
                name='date'
                dateFormat="MMM d, yyyy"
                // minDate={new Date()}
                selected={newConviction.date}
                value={newConviction.date}
                className='form-control'
                onChange={ev => setNewConviction({ ...newConviction, 'date': ev })}
                validators={['required']}
                errorMessages={['Please enter a valid date']}
              />
            </td>
            <td>
              <Fields.Select
                name='court'
                options={courts}
                value={newConviction.court}
                values={courts.filter(x => x.value === newConviction.court)}
                // onChange={ev => console.log(ev, ev.target)}
                onChange={ev => setNewConviction({ ...newConviction, 'court': ev?.length > 0 ? ev[0].value : '' })}
                className='form-control'
                validators={['required']}
                errorMessages={['Required']}
                required
              />
            </td>
            <td>
              <Fields.Textarea
                icon={null}
                name='conviction'
                value={newConviction.conviction}
                className='form-control'
                // onChange={ev => console.log(ev, ev.target, ev.target?.value)}
                onChange={ev => setNewConviction({ ...newConviction, [ev?.target?.name]: ev?.target?.value })}
                validators={['required']}
                errorMessages={['Required']}
                maxLength={120}
                required
              />
            </td>
            <td>
              <Fields.Textarea
                icon={null}
                name='sentence'
                value={newConviction.sentence}
                className='form-control'
                onChange={ev => setNewConviction({ ...newConviction, [ev?.target?.name]: ev?.target?.value })}
                validators={['required']}
                errorMessages={['Required']}
                maxLength={120}
                required
              />
            </td>
            <td>
              <button type='submit' className='btn btn-round btn-info'>
                Add Conviction
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )

  const tables = () => {
    if (!Array.isArray(criminalFields)) return null
    if (criminalFields.length < 1) return null

    // console.log(criminalFields);

    // const getRowClass = status => {
    //   switch (status) {
    //     case 'surplus':
    //       return 'table-success'
    //     case 'denied':
    //       return 'table-danger'
    //     case 'local purchase':
    //       return 'table-warning'
    //     default:
    //       return 'table-default'
    //   }
    // }

    const rows = criminalFields.map((row, i) => {
      return (
        <tr key={i} className={'table-default'}>
          <td>
            <Fields.Date
              name='date'
              dateFormat="MMM d, yyyy"
              // minDate={new Date()}
              selected={row?.date}
              value={row?.date}
              className='form-control'
              onChange={ev => on.setInput(ev, i, 'date')}
              validators={['required']}
              errorMessages={['Please enter a valid date']}
            />
          </td>
          <td>
            <Fields.Select
              name='court'
              options={courts}
              values={courts.filter(x => x.value === row.court)}
              onChange={ev => on.setInput(ev[0].value, i, 'court')}
              className='form-control'
              // validators={['required']}
              // errorMessages={['Required']}
              // required
            />
          </td>
          <td>
            <Fields.Textarea
              icon={null}
              name='conviction'
              value={row?.conviction}
              className='form-control'
              onChange={ev => on.setInput(ev.target.value, i, 'conviction')}
              validators={['required']}
              errorMessages={['Required']}
              maxLength={120}
            />
          </td>
          <td>
            <Fields.Textarea
              icon={null}
              name='sentence'
              value={row?.sentence}
              className='form-control'
              onChange={ev => on.setInput(ev.target.value, i, 'sentence')}
              validators={['required']}
              errorMessages={['Required']}
              maxLength={120}
            />
          </td>
          <td>
            <button type='button' className='btn btn-round btn-danger' onClick={() => removeConvict(row)}>
              Remove Conviction
            </button>
          </td>
        </tr>
      )
    })

    return (
      <div key={'criminal_history'} className='form-wizard-form-table table'>
        <table className='table table-striped table-striped-bg-black'>
          {colgroup}
          {headings}
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div style={{ maxWidth: '100%', width: '1280px' }}>
      <div className='modal-header'>
        <h5 className='modal-title' style={{ paddingLeft: '10px', textTransform: 'capitalize' }}>
          Criminal History
          <br/>
          <b>{tableName}</b>
        </h5>
      </div>
        <div className='modal-body'>
          <ValidatorForm id='criminal-history-items' onSubmit={formSubmit}>
            {tables()}
          </ValidatorForm>
          <ValidatorForm id='add-criminal-history-items' onSubmit={addConvict}>
            {addItemRow}
          </ValidatorForm>
          <br />
          <div className='clearfix'>
            <strong style={{ paddingLeft: '10px' }}>Total # of Convictions:</strong>
            <strong className='float-right'>
              {Number(totalConvictions) || 'N/A'}
            </strong>
          </div>
        </div>
      <div className='modal-footer'>
        <button className='btn btn-round mr-2' onClick={closeModal}>
          Cancel
        </button>
        <input
          className='btn custom-btn btn-round'
          form='criminal-history-items'
          type='submit'
          value='Save History'
        />
      </div>
    </div>
  )
}
  
export {
  ItemCriminalHistory
}
