import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'

export default class GarageLicense extends React.Component {
  resource = '/garage_licenses'
  form_key = 'garage_license'
  defaults = {}

  // Delete lines as needed
  hide_buttons = false
  custom_actions = []
  skip_location = true

  validate = null

  foremost = null

  fee = form => 100

  fields = form => [
    {
      name: 'renewal',
      save: true,
      hide: true,
    },
    {
      name: 'transfer',
      save: true,
      hide: true,
    },
    {
      name: 'license_numb',
      label: 'License Number',
    },
    {
      name: 'license_expiry_date',
      label: 'Licence expiry date',
      type: 'date',
      hide: true,
      view: true,
    },
    {
      name: 'license_approved_date',
      label: 'License approval date',
      type: 'date',
      hide: true,
      view: true,
    },
    {
      name: 'location_address',
      label: 'Address',
    },
    {
      name: 'location_island',
      label: 'Island',
      options: islands,
    },
    {
      name: 'location_desc',
      label: 'Location description',
      type: 'textarea',
    },
    "::Owner's Details",
    {
      name: 'owner_first_name',
      label: 'First name',
    },
    {
      name: 'owner_last_name',
      label: 'Last Name',
    },
    {
      name: 'owner_consent_desc',
      label: "Owner's consent decription",
      required: false,
    },
    {
      name: 'owner_consent',
      labvel: 'Owner consent',
      type: 'select:bool',
    },
    {
      name: 'number_of_persons',
      label: 'Number of persons',
      type: 'integer',
    },

    ':: Manager Details',
    {
      name: 'manager_first_name',
      label: 'First name',
    },
    {
      name: 'manager_last_name',
      label: 'Last name',
    },
    {
      name: 'date_of_issue',
      label: 'Issue date',
      type: 'date',
    },
    {
      name: 'old_license_name',
      label: 'Name on old license',
    },
    {
      name: 'old_license_operation_type',
      options: ['Major repairs', 'Paint and body work', "Wrecker's Yard"],
    },
    {
      name: 'site_plan_upload',
      label: 'Site plan',
      key: 'image.1',
      type: 'file:all',
    },
  ]
  hidden_fields = () => [
    {
      name: 'pdf_upload',
      label: 'Upload pdf',
      key: 'image.0',
    },
  ]
  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
