import React from 'react'
import Swal from 'sweetalert2'
import { GrCertificate, GrClose } from 'react-icons/gr'

import Wizard from '@modules/form-wizard'
import toastr from '@modules/toastr'
import { fullName } from '@modules/form-wizard/utils'
import { listKeys } from '@helpers/objects'
import SERVICE_PROPS from '@helpers/extract-service-props'
import occupations from '@constants/occupations'
import countries from '@constants/countries'
import islands from '@constants/islands'

import passportPhotoExample from '@helpers/passport-photo-example'
import { ItemCriminalHistory } from './police_criminal_history_list'
import Modal from 'react-responsive-modal'
import Loader from '@shared/loader'

export default class CharacterReference extends React.Component {
  state = {
    loading: false,
    record: null,
    criminal_history: [],
  }

  title = 'Character Certificate (Digital)'
  resource = '/police_certificates'
  form_key = 'police_certificate'

  hide_buttons  = {
    status: true,
    deny: false,
  }

  defaults = {
    digital_cert_only: true,
    physical_copy: false,
  }

  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        digital_cert_only: true,
        physical_copy: false
      },
    },
  }

  // skip_location = form => form.delivery_method == 'DIGITAL'
  skip_location = true

  get isBahamian() {
    return $app.applicant?.country_of_citizenship == 'bahamas'
  }

  defaults = () => {
    const res = {
      full_name: fullName($app.applicant),
      amount: 1,
    }

    if (this.isBahamian) {
      res.birth_place = 'Bahamas'
      res.passport_issue_country = 'Bahamas'
    }

    return res
  }

  showPassort = (form) => {
    if (this.isBahamian) {
      if (form.travel_job != 'Travel') {
        return true
      }
    } else {
      return false
    }
  }

  canned = [
    `
    Thank you for your request. We require that you come in person for a finger print scanning.
    Please visit the Royal Bahamas Police Force Head Office at New Providence, Thompson Blvd. to complete your application process
    `,
  ]

  /* The `fee` function is calculating the fee for the service based on the form data. It takes in the
  `form` object as a parameter and returns a number representing the fee. */
  fee = form => {
    const rate = $app.applicant.verified_citizenship ? 5 : 10
    return rate

    // Below code is only needed once multiple copies can be requested
    // if (form.delivery_method == 'DIGITAL') return rate

    // // if the delivery amount is BOTH, the
    // // digital amount will be added to the total before
    // // multiplication of amount requested
    // let amount = form.delivery_method == 'PHYSICAL' ? 0 : rate
    // return amount + (rate * form.copies_requested)
  }

  custom_actions = [
    {
      text: 'Criminal History',
      icon: 'user-tie',
      roles: [['officer', 'senior_officer', 'director']],
      test: r =>
        r.payment_status &&
        ['pending', 'officer viewed', 'finger print', 'processing'].includes(r.application_decision),
      fn: record => this.setState({ record }),
    },
    {
      text: 'Request Prints',
      icon: 'fingerprint',
      fn: async record => {
        const person = listKeys(
          record.user,
          'first_name',
          'middle_name',
          'last_name'
        )
          .filter(n => n)
          .join(' ')
          .capitalize()

        const { isConfirmed } = await Swal.fire({
          icon: 'question',
          title: 'Request Fingerprints',
          text: `Are you sure want to request that ${person} come in for fingerprinting?`,
          showCancelButton: true,
        })

        if (isConfirmed) {
          const { resource, form_key } = this
          this.setState({ loading: true })

          try {
            await $app.axios.put(resource + '/update_application', {
              [form_key]: {
                form_num: record.form_num,
                application_decision: 'finger print',
                finger_print_rq: true,
              },
            })

            await $app.axios.post('/emails', {
              email: record.user.email,
              subject: 'MyGateqway Portal Update - Character Reference Request',
              message: `
                Good day ${record.user.first_name.capitalize()},
                <br/>
                In order to complete your Character Reference request, we ask that you
                come in person to the Central Detective Unit located on Thompson Blvd.,
                New Providence for fingerprinting.
                <br/><br />
                Thank you for using MyGateway Portal.
              `,
            })
          } catch (err) {
            console.error(err)
          } finally {
            this.setState({ loading: false })
          }
        }
      },
    },
    {
      text: 'Set as Processing',
      icon: <GrCertificate className='text-blue-600' />,
      roles: ['officer'],
      test: r => r.digital_cert_only,
      fn: async record => {
        const { isConfirmed } = await Swal.fire({
          icon: 'question',
          title: 'Set as Processing',
          html: `
            Set the application status as processing for,
            <strong>${fullName(record.user, 'initial')}</strong>?
          `,
          showCancelButton: true,
        })

        if (isConfirmed) {
          this.setState({ loading: true })

          const { props, resource, form_key } = this
          const { history, location } = props
  
          try {
            await $app.axios.put(resource + '/update_application', {
              [form_key]: {
                form_num: record.form_num,
                application_decision: 'processing',
              },
            })
  
            if (location.state?.view) {
              const view = { ...location.state.view }
              view.application_decision = 'processing'
  
              history.replace(location.pathname, { ...location.state, view })
            }
  
            toastr.success('Success', 'Application processed')
          } catch (error) {
            console.error(error)
            toastr.error('Error', 'Unable to process application')
            return
          }
  
          this.setState({ loading: false, record: null })
        }
      }
    },
    {
      text: 'Set Licence #',
      icon: <GrCertificate className='text-blue-600' />,
      roles: ['officer'],
      test: r => r.digital_cert_only,
      fn: async record => {
        const { value: license_numb, isDismissed } = await Swal.fire({
          title: 'Set Licence Number',
          input: 'text',
          inputLabel: 'Tag Number',
          inputPlaceholder: 'Tag Number',
          text: `Please set the applicant's licence #`,
          showCancelButton: true,
          confirmButtonText: 'Set Licence #',
        })

        if (isDismissed) return
        this.setState({ loading: true })

        const { props, resource, form_key } = this
        const { history, location } = props

        try {
          await $app.axios.put(resource + '/update_application', {
            [form_key]: {
              form_num: record.form_num,
              application_decision: 'approved',
              license_numb,
            },
          })

          if (location.state?.view) {
            const view = { ...location.state.view }
            view.application_decision = 'approved'

            history.replace(location.pathname, { ...location.state, view })
          }

          toastr.success('Success', 'Application Licence # set')
        } catch (error) {
          console.error(error)
          toastr.error('Error', 'Unable to set licence #')
          return
        }

        this.setState({ loading: false, record: null })
      }
    },
    {
      text: 'Process Digital Print',
      icon: <GrCertificate className='text-blue-600' />,
      roles: [['senior_officer', 'director']],
      test: r => r.digital_cert_only && ['officer viewed', 'senior officer viewed'].includes(r.application_decision),
      fn: async record => {
        const isFinalApprover = true // $app.hasAnyRole(['director']);

        const { value, isDismissed } = await Swal.fire({
          title: 'Process Application',
          html: `
            What is your recommendation regarding the applicant,
            <strong>${fullName(record.user, 'initial')}</strong>?
          `,
          input: 'select',
          inputOptions: {
            'approved': isFinalApprover ? "I approve this applicant" : "I recommend this applicant",
            'denied': isFinalApprover ? "I will deny this applicant" : "I do not recommend this applicant",
          },
          showCancelButton: true,
          confirmButtonText: 'Send',
        })

        if (isDismissed) return
        this.setState({ loading: true })

        const { props, resource, form_key } = this
        const { history, location } = props

        try {
          await $app.axios.put(resource + '/update_application', {
            [form_key]: {
              form_num: record.form_num,
              application_decision: value,
            },
          })

          if (location.state?.view) {
            const view = { ...location.state.view }
            view.application_decision = isFinalApprover ? value : 'senior officer viewed'

            history.replace(location.pathname, { ...location.state, view })
          }

          toastr.success('Success', 'Application processed')
        } catch (error) {
          console.error(error)
          toastr.error('Error', 'Unable to process application')
          return
        }

        this.setState({ loading: false, record: null })
      }
    },
    {
      text: 'Recall Certificate',
      icon: <GrClose className='text-blue-600' />,
      roles: [['senior_officer', 'director']],
      test: r => r.digital_cert_only && ['approved'].includes(r.application_decision),
      fn: async record => {
        const { isConfirmed } = await Swal.fire({
          icon: 'question',
          title: 'Recall Certificate',
          html: `
            Deny an approved certificate,
            <strong>${fullName(record.user, 'initial')}</strong>?
          `,
          showCancelButton: true,
        })

        if (isConfirmed) {
          this.setState({ loading: true })

          const { props, resource, form_key } = this
          const { history, location } = props
  
          try {
            await $app.axios.put(resource + '/update_application', {
              [form_key]: {
                form_num: record.form_num,
                application_decision: 'cancelled',
              },
            })
  
            if (location.state?.view) {
              const view = { ...location.state.view }
              view.application_decision = 'cancelled'
  
              history.replace(location.pathname, { ...location.state, view })
            }
  
            toastr.success('Success', 'Application processed')
          } catch (error) {
            console.error(error)
            toastr.error('Error', 'Unable to process application')
            return
          }
  
          this.setState({ loading: false, record: null })
        }
      }
    },
  ]

  validate = form => {
    const updates = {}

    // Below code is only needed once multiple copies can be requested
    // if (form.copies_requested) {
    //   if (form.copies_requested < 1 || form.copies_requested > 10)
    //     return 'For # of copies, please enter an amount between 1 and 10.'
    // }

    switch (form.delivery_method) {
      case 'PHYSICAL':
        updates.physical_copy = true
        updates.digital_cert_only = false
        break
      case 'DIGITAL':
        updates.digital_cert_only = true
        updates.physical_copy = false
        break
      case 'BOTH':
        updates.physical_copy = true
        updates.digital_cert_only = true
        break
      default:
        toastr.warning('Response Needed', 'Please select a type of certificate')
        return false

    }

    if (form.full_name.trim().split(' ').length < 3 && !form.no_middle_name) {
      return 'Please be sure to enter your middle name'
    }

    return updates
  }

  fields = form => [
    {
      name: 'criminal_history_list',
      label: 'Items to be Imported',
      columns: [
        {
          name: 'date',
          label: 'Date',
          is: 'date',
          width: 32
        },
        {
          name: 'court',
          label: 'Court',
          // is: 'integer',
          width: 52,
        },
        {
          name: 'conviction',
          label: 'Conviction',
          // is: 'integer',
          width: 74,
        },
        {
          name: 'sentence',
          label: 'Sentence',
          // is: 'integer',
          width: 52,
        },
      ],
      hide: true,
    },
    {
      name: 'travel_job',
      label: 'Purpose',
      options: ['Travel', 'Employment', 'Immigration'],
      hint: 'Will this document be used for travel, employment or immigration?',
    },
    {
      name: 'digital_cert_only',
      label: 'Digital Certificate',
      type: 'select:bool',
      hide: true,
      save: true,
      view: true,
    },
    {
      name: 'physical_copy',
      label: 'Physical Copy',
      type: 'select:bool',
      hide: true,
      save: true,
      view: true,
    },
    {
      name: 'delivery_method',
      type: 'radio',
      view: false,
      label: 'Type of Certificate',
      options: [
        { value: 'DIGITAL', label: 'DIGITAL' },
        // { value: 'PHYSICAL', label: 'PHYSICAL' },
        // { value: 'BOTH', label: 'PHYSICAL & DIGITAL' }
      ],
    },
    // {
    //   name: 'copies_requested',
    //   label: '# of Copies',
    //   type: 'number',
    //   is: 'integer',
    //   min: 1,
    //   hide: form.delivery_method == 'DIGITAL'
    // },
    {
      name: 'passport_verified',
      admin: true,
    },
    'street_address',
    {
      name: 'birth_place',
      label: 'Country Of Birth',
      options: countries,
    },
    {
      name: 'birth_island',
      label: 'Island Of Birth',
      options: islands,
      hide: form.birth_place !== 'Bahamas',
      use_profile: 'island',
    },
    {
      name: 'occupation',
      options: occupations,
    },
    {
      heading: 'Passport Details',
      hide: form.travel_job != 'Travel',
    },
    {
      name: 'passport_number',
      hide: form.travel_job != 'Travel',
    },
    {
      name: 'full_name',
      hint: "Enter the full name on your passport if it's not the same as your NIB",
      save: true,
      view: !!form.full_name,
      required: !form.no_middle_name,
      disabled: form.no_middle_name,
    },
    {
      name: 'no_middle_name',
      view: false,
      type: 'checkbox',
      label: 'I have no middle name',
      required: false,
    },
    {
      name: 'passport_issue_date',
      label: 'Issue Date',
      type: 'date',
      showMonthDropdown: true,
      showYearDropdown: true,
      hide: form.travel_job != 'Travel',
    },
    {
      name: 'passport_expiry_date',
      label: 'Expiration Date',
      type: 'date',
      showMonthDropdown: true,
      showYearDropdown: true,
      hide: form.travel_job != 'Travel',
    },
    {
      name: 'identification',
      type: 'file:image',
      label: 'Identification',
      key: f => f.image[0],
      hide: this.showPassort(form),
      tooltip: 'Only the information page is required',
      hint: passportPhotoExample,
    },
    {
      name: 'passport_issue_country',
      label: 'Issuing Country',
      hide: form.travel_job != 'Travel',
      disabled: this.isBahamian,
      options: countries,
    },
    {
      name: 'work_permit_upload',
      label: 'Work Permit',
      type: 'file:image',
      key: f => f.image[1],
      hide: this.isBahamian,
    },
    {
      name: 'photo_upload',
      type: 'file:image',
      label: 'Self Portrait',
      key: f => f.image[2],
      tooltip: 'A recent high quality headshot self portrait',
      hint: 'A recent high quality headshot self portrait',
      // hint: passportPhotoExample,
    },
  ]

  hidden_fields = form => [
    {
      key: 'image.3',
      name: 'pdf_upload',
      type: 'file:pdf',
      label: 'Character Certificate (Summary)',
      hide: !form.digital_cert_only,
    },
    {
      key: 'image.4',
      name: 'pdf_upload_2',
      type: 'file:pdf',
      label: 'Character Certificate (Full)',
      hide: !form.digital_cert_only,
    }
  ]

  closeModal = ev => {
    ev.preventDefault()
    this.setState({ record: null })
  }

  saveHistorySubmit = async approvedState => {

  }

  render () {
    const {
      state,
      props,
      closeModal,
      fields,
      resource,
      form_key,
      update,
      saveHistorySubmit,
    } = this
    const { record, loading } = state

    const fprops = {
      update,
      resource,
      closeModal,
      form_key,
      location: props.location,
      history: props.history,
      submit: saveHistorySubmit,
    }

    if (record) {
      Object.assign(fprops, {
        record,
        fields: fields(record),
        tableName: `${record.user.first_name} ${record.user.no_middle_name || ''} ${record.user.last_name}`,
      })
    }

    return (
      <React.Fragment>
        <Loader loading={loading} />
        <Wizard {...SERVICE_PROPS(this)} />
        <Modal
          open={!!record}
          onClose={closeModal}
          center
          styles={{ modal: { maxWidth: '100%', width: 1000 } }}
        >
          {fprops?.record && <ItemCriminalHistory record={record} fields={fprops['fields']} submit={fprops['submit']} closeModal={fprops['closeModal']} tableName={fprops['tableName']} />}
        </Modal>
      </React.Fragment>
    )
  }
}
