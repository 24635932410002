import React from 'react'

import Wizard from '@modules/form-wizard'
import { fullName } from '@modules/form-wizard/utils'
import SERVICE_PROPS from '@helpers/extract-service-props'

import passportPhotoExample from '@helpers/passport-photo-example'
import islands from '@constants/islands'

export default class CriminalHistory extends React.Component {
  state = {
    loading: false,
  }

  // title = 'Criminal History (Northern Islands Division)'
  resource = '/criminal_histories'
  form_key = 'criminal_history'

  // read_params = {
  //   admin: true,
  //   label: ' ',
  //   default: 'Default',
  //   values: {
  //     'Default': {
  //     },
  //   },
  // }

  componentDidMount = () => {
    console.log($app.applicant)
  }

  home_address = () => {
    const addresses = $app.applicant.addresses;

    return addresses?.filter(x => x.address_type == 'home')[0]
  }

  defaults = () => {
    const res = {
      full_name: fullName($app.applicant),
      date_of_birth: $app.applicant.date_of_birth,
      street_address: this.home_address().street,
      island: this.home_address().island
    }

    return res
  }

  // fee = () => ($app.applicant.verified_citizenship ? 5 : 10)

  custom_action = []

  // validate = form => {
  //   if (form.full_name.trim().split(' ').length < 3 && !form.no_middle_name) {
  //     return 'Please be sure to enter your middle name'
  //   }
  // }

  fields = form => [
    {
      name: 'island',
      label: 'Island',
      options: ['Northern Division Islands'],
      hint: 'Form application only applicable to Northern Division Islands for the time being',
    },

    '::Applicant Details',
    {
      name: 'full_name',
      save: false,
      disabled: true,
    },
    {
      name: 'date_of_birth',
      label: 'Date of Birth',
      type: 'date',
      showMonthDropdown: true,
      showYearDropdown: true,
      save: false,
      disabled: true,
    },
    {
      name: 'island',
      save: true,
      disabled: false,
      options: islands,
    },
    {
      name: 'street_address',
      save: true,
      disabled: false,
    },
  
    {
      heading: 'Passport Details',
      // hide: form.travel_job != 'Travel',
    },
    {
      name: 'passport_number',
      // hide: form.travel_job != 'Travel',
    },
    {
      name: 'passport_issue_date',
      label: 'Issue Date',
      type: 'date',
      showMonthDropdown: true,
      showYearDropdown: true,
    //   hide: form.travel_job != 'Travel',
    },
    {
      name: 'passport_expiry_date',
      label: 'Expiration Date',
      type: 'date',
      showMonthDropdown: true,
      showYearDropdown: true,
    //   hide: form.travel_job != 'Travel',
    },

    "::Uploads",
    {
      name: 'identification',
      type: 'file:image',
      label: 'Identification',
      key: f => f.image[0],
    //   hide: this.showPassort(form),
      tooltip: 'Only the information page is required',
      hint: passportPhotoExample,
    },
    {
      name: 'embassy_document_upload',
      label: 'Embassy Document',
      type: 'file:image',
      key: f => f.image[1],
    //   hide: this.isBahamian,
    },
    {
      name: 'authorization_letter_upload',
      label: 'Authorization Letter',
      type: 'file:image',
      key: f => f.image[2],
      //   hide: this.isBahamian,
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
